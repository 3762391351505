










import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface LampDefinition {
  element: string;
  color: string;
  spectrumUrl: string;
}

export default defineComponent({
  name: 'LabSpectroscope',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    lamps: {
      type: Array as PropType<LampDefinition[]>,
      default: () => [],
    },
  },
  computed: {
    stringifiedLamps(): string {
      return JSON.stringify(this.lamps);
    },
    userAgentSpec(): object {
      return {
        ChromeOS: {
          chrome: '>=1',
          safari: '>=1',
          firefox: '>=1',
        },
        mobile: {
          safari: '>=1',
          chrome: '>=1',
          firefox: '>=1',
        },
        tablet: {
          chrome: '>=1',
          firefox: '>=1',
          safari: '>=1',
        },
        desktop: {
          edge: '>=1',
          firefox: '>=1',
          chrome: '>=1',
        },
      };
    },
  },
});
