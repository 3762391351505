<template>
  <lab-task><lab-spectroscope :lamps="lamps" /></lab-task>
</template>

<script>
import LabSpectroscope from '../simulations/LabSpectroscope';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import LabTask from './labs/LabTask';

const PREFIX_URL = '/assets/img/spectro_images';
const UNKNOWN_ELEMENT_COLORS = {
  1: '#F1AEF0',
  2: '#FFFFFF',
  3: '#F9DBC9',
  4: '#F9F2FF',
  5: '#ff3c00',
  6: '#F0C5F7',
};

export default {
  name: 'Spectroscope',
  components: {LabSpectroscope, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    unknownElementId() {
      return this.taskState.getValueBySymbol('unknownElementId').content.value;
    },
    dSpacing() {
      return this.taskState.getValueBySymbol('dSpacing').content.value;
    },
    lamps() {
      return [
        {
          element: 'Sodium',
          color: '#f59e42',
          spectrumUrl: `${PREFIX_URL}/sodium_w4000_ppd50_d${this.dSpacing}.png`,
        },
        {
          element: 'Hydrogen',
          color: '#F0C5F7',
          spectrumUrl: `${PREFIX_URL}/hydrogen_w4000_ppd50_d${this.dSpacing}.png`,
        },
        {
          element: 'Mercury',
          color: '#D6FFFF',
          spectrumUrl: `${PREFIX_URL}/mercury_w4000_ppd50_d${this.dSpacing}.png`,
        },
        {
          element: `Unknown ${this.unknownElementId}`,
          color: UNKNOWN_ELEMENT_COLORS[this.unknownElementId],
          spectrumUrl: `${PREFIX_URL}/unknown_${this.unknownElementId}_w4000_ppd50_d${this.dSpacing}.png`,
        },
      ];
    },
  },
};
</script>
